import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Logger } from '../../../app/services/logger.service';
const log = new Logger('ErrorInterceptor');
import { AuthenticationService } from 'app/auth/service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  /**
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(private _router: Router, private _authenticationService: AuthenticationService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        log.error(err);
        if ([403].indexOf(err.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          this._router.navigate(['/pages/miscellaneous/not-authorized']);

          // ? Can also logout and reload if needed
          // this._authenticationService.logout();
          // location.reload(true);
        }
        if ([401].indexOf(err.status) !== -1) {
          // auto logout if 401 Unauthorized
          // this._router.navigate(['/login']);

          let account:any = JSON.parse(localStorage.getItem("currentUser"));
          if(account){
            const refreshToken = account.refreshToken;
            log.debug("old refreshToken %o",refreshToken)

            this._authenticationService.refreshTokenPromise( refreshToken ).then((tokens:any) => {
              log.debug("new tokens %o",tokens)
              if(!tokens){
                this._authenticationService.logout();
                this._router.navigate(['/login'])
                return true
              }else{
                log.debug(`success call refresh-token`);
                location.reload();
                return true
                // return next.handle(this.addTokenHeader(request, tokens.access.token));
              }
            })
          }
        }
        // throwError
        const error = err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }
}
