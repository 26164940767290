import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fieldRender'
})
export class FieldRenderPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    return value ? value : '-';
  }

}
