<div class="content-wrapper">
    <div class="content-body">
        <!-- content-header component -->
        <app-content-header [contentHeader]="contentHeader"></app-content-header>


        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Document</h4>
                        </div>
                        <div class="card-body pb-2">
                            <p class="card-text">Start building your smart contract using LINX DASHBOARD.</p>

                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>
</div>
