import { TranslateService } from '@ngx-translate/core';
import { async } from '@angular/core/testing';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { environment } from 'environments/environment';

import { ethers } from 'ethers';
import WalletConnectProvider from '@walletconnect/web3-provider';

import { Logger } from './logger.service';
const log = new Logger('SingletonService');

@Injectable({
  providedIn: 'root'
})
export class SingletonService {

  public currentLanguage = new Subject<any>();
  private themeSubject = new Subject<any>();
  theme: string;

  constructor(
    private window: Window,
    private router: Router,
    private http: HttpClient,
    private translate: TranslateService,
  ) {
    this.theme = localStorage.getItem('theme');
  }

  async getTheme() {
    let _theme = localStorage.getItem('theme');
    if (!_theme) {
      _theme = 'dark';
      await localStorage.setItem('theme', _theme);
    }
    return _theme;
  }

  async changeTheme(_theme) {
    await localStorage.setItem('theme', _theme);
    this.themeSubject.next(_theme);
  }

  getThemeSubject(): Observable<any> {
    return this.themeSubject.asObservable();
  }

  getCurrentLang(): Observable<any> {
    return this.currentLanguage.asObservable();
  }

  async switchLanguage(lang) {
    if (localStorage.getItem('appLanguage')) {
      localStorage.removeItem('appLanguage');
      localStorage.setItem('appLanguage', lang);
    } else {
      localStorage.setItem('appLanguage', lang);
    }
    this.currentLanguage.next(lang);
  }

}
