<div class="content-wrapper">
    <div class="content-body">
        <!-- content-header component -->
        <app-content-header [contentHeader]="contentHeader"></app-content-header>
        <h4>Smart Contract Code or Github link*</h4>

        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card mt-1">
                        <div class="card-header">
                            <h4 class="card-title">Consent and Information</h4>

                        </div>
                        <div class="card-body pb-2">
                             <div class="terms">
                                 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam eleifend ante eu posuere euismod. Fusce accumsan dui mattis tellus hendrerit porta. Aenean diam dui, gravida a nisl et, tempor fermentum justo. Donec vel cursus nulla. Duis vitae eros scelerisque sapien venenatis convallis eu id arcu. Vivamus at orci aliquam, pellentesque leo vel, condimentum quam. Cras at tellus vel dolor interdum consectetur id ac dui. Vivamus arcu turpis, elementum sit amet volutpat eget, suscipit at tortor. In aliquet, est in accumsan commodo, nunc felis bibendum nisi, non tempor ex dui non dui. Ut at dignissim nulla.
                                    <br/><br/>
                                 Nulla sed viverra arcu, sed ultricies lectus. Fusce aliquam facilisis viverra. Aenean ut mattis elit. Nam eleifend rhoncus quam, eu interdum purus ultrices sit amet. Duis faucibus sed augue vel convallis. Mauris dictum sed sem vel fringilla. Vivamus mollis tellus nec ligula viverra pretium. In gravida efficitur enim, id auctor magna molestie vel. Ut congue orci odio, quis cursus enim vestibulum nec. Nam porta nibh nec dignissim lobortis.
                                 <br/><br/>
                                 Nam interdum gravida turpis, eu scelerisque dolor dapibus nec. Fusce at purus ac urna dignissim semper sit amet vel odio. Nunc pharetra, leo euismod lobortis sollicitudin, diam elit molestie nunc, et placerat nulla dolor vitae libero. Nam placerat aliquet felis, at gravida velit. Aenean eget augue felis. Morbi vitae magna lacinia justo feugiat tincidunt. Pellentesque ut lorem turpis. Etiam vulputate metus vulputate ullamcorper posuere. Mauris at viverra justo, vitae lobortis turpis. Sed egestas, metus ac varius aliquam, eros sapien ultricies odio, sed scelerisque leo nisl sit amet mi. Duis augue libero, rutrum a nulla a, gravida pellentesque magna. Cras mattis ligula vel consequat tempus. Suspendisse mauris nibh, ultrices nec tempus vel, euismod non risus. Sed auctor neque quam, eu fermentum quam semper ut. Interdum et malesuada fames ac ante ipsum primis in faucibus.
                                 <br/><br/>
                                 Vestibulum congue euismod dignissim. Sed facilisis iaculis aliquam. Etiam at tempor dui, id efficitur nibh. Suspendisse varius rutrum velit, vitae iaculis mauris commodo sit amet. Donec ex augue, faucibus quis lectus at, scelerisque interdum sem. In scelerisque metus odio, vel semper metus imperdiet et. Aliquam ut porta urna.
                                 <br/><br/>
                                 Sed nec sapien mi. Aenean nec molestie quam, sollicitudin iaculis nunc. Vivamus placerat in dolor vel fringilla. Etiam ornare sem ut molestie efficitur. Nam auctor aliquam scelerisque. Morbi vitae tortor blandit, sollicitudin lorem ut, consectetur elit. Nullam quis pharetra purus, eu luctus nisl. Donec fermentum luctus erat, non dapibus ligula viverra eu. Mauris eget interdum ex. Suspendisse condimentum congue nunc. Donec dapibus ligula nec est finibus, vulputate sagittis nisi laoreet. Fusce cursus ante mollis sagittis pharetra. Vestibulum justo est, sollicitudin a odio facilisis, convallis tempor quam.
                                 <br/><br/>
                                 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam eleifend ante eu posuere euismod. Fusce accumsan dui mattis tellus hendrerit porta. Aenean diam dui, gravida a nisl et, tempor fermentum justo. Donec vel cursus nulla. Duis vitae eros scelerisque sapien venenatis convallis eu id arcu. Vivamus at orci aliquam, pellentesque leo vel, condimentum quam. Cras at tellus vel dolor interdum consectetur id ac dui. Vivamus arcu turpis, elementum sit amet volutpat eget, suscipit at tortor. In aliquet, est in accumsan commodo, nunc felis bibendum nisi, non tempor ex dui non dui. Ut at dignissim nulla.
                                 <br/><br/>
                                 Nulla sed viverra arcu, sed ultricies lectus. Fusce aliquam facilisis viverra. Aenean ut mattis elit. Nam eleifend rhoncus quam, eu interdum purus ultrices sit amet. Duis faucibus sed augue vel convallis. Mauris dictum sed sem vel fringilla. Vivamus mollis tellus nec ligula viverra pretium. In gravida efficitur enim, id auctor magna molestie vel. Ut congue orci odio, quis cursus enim vestibulum nec. Nam porta nibh nec dignissim lobortis.
                                 <br/><br/>
                                 Nam interdum gravida turpis, eu scelerisque dolor dapibus nec. Fusce at purus ac urna dignissim semper sit amet vel odio. Nunc pharetra, leo euismod lobortis sollicitudin, diam elit molestie nunc, et placerat nulla dolor vitae libero. Nam placerat aliquet felis, at gravida velit. Aenean eget augue felis. Morbi vitae magna lacinia justo feugiat tincidunt. Pellentesque ut lorem turpis. Etiam vulputate metus vulputate ullamcorper posuere. Mauris at viverra justo, vitae lobortis turpis. Sed egestas, metus ac varius aliquam, eros sapien ultricies odio, sed scelerisque leo nisl sit amet mi. Duis augue libero, rutrum a nulla a, gravida pellentesque magna. Cras mattis ligula vel consequat tempus. Suspendisse mauris nibh, ultrices nec tempus vel, euismod non risus. Sed auctor neque quam, eu fermentum quam semper ut. Interdum et malesuada fames ac ante ipsum primis in faucibus.
                                 <br/><br/>
                                 Vestibulum congue euismod dignissim. Sed facilisis iaculis aliquam. Etiam at tempor dui, id efficitur nibh. Suspendisse varius rutrum velit, vitae iaculis mauris commodo sit amet. Donec ex augue, faucibus quis lectus at, scelerisque interdum sem. In scelerisque metus odio, vel semper metus imperdiet et. Aliquam ut porta urna.
                                 <br/><br/>
                                 Sed nec sapien mi. Aenean nec molestie quam, sollicitudin iaculis nunc. Vivamus placerat in dolor vel fringilla. Etiam ornare sem ut molestie efficitur. Nam auctor aliquam scelerisque. Morbi vitae tortor blandit, sollicitudin lorem ut, consectetur elit. Nullam quis pharetra purus, eu luctus nisl. Donec fermentum luctus erat, non dapibus ligula viverra eu. Mauris eget interdum ex. Suspendisse condimentum congue nunc. Donec dapibus ligula nec est finibus, vulputate sagittis nisi laoreet. Fusce cursus ante mollis sagittis pharetra. Vestibulum justo est, sollicitudin a odio facilisis, convallis tempor quam.
                                 <br/><br/>
                             </div>

                            <div class="custom-control custom-checkbox mt-2">
                                <input type="checkbox" class="custom-control-input" id="customCheck1" />
                                <label class="custom-control-label" for="customCheck1">My contract address different from the above.</label>
                            </div>

                            <div class="row mt-2">
                                <div class="col-md-12 text-center">
                                    <button type="button" class="btn btn-gradient-primary" rippleEffect (click)="openPopupSubcription()">Accept</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>
</div>
