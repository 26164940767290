import { ContentHeaderModule } from "./layout/components/content-header/content-header.module";
import { DocsComponent } from "./main/views/docs/docs.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { PrivacyComponent } from "./main/views/privacy/privacy.component";
import { AuthGuard } from "./auth/helpers/auth.guards";
import { DashboardComponent } from "./main/views/dashboard/dashboard.component";
import { AuthLoginV2Component } from "./main/pages/authentication/auth-login-v2/auth-login-v2.component";
import { AttendeeListComponent } from "./main/views/attendee/attendee-list/attendee-list.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "/login",
    pathMatch: "full",
  },
  {
    path: "dashboard",
    canActivate: [AuthGuard],
    data: {
      roles: ["admin", "staff", "attendee"],
    },
    component: DashboardComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./main/views/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
      },
      { path: "", redirectTo: "dashboard", pathMatch: "full" },
    ],
  },
  {
    path: "login",
    component: AuthLoginV2Component,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./main/pages/authentication/authentication.module").then(
            (m) => m.AuthenticationModule
          ),
      },
      { path: "", redirectTo: "login", pathMatch: "full" },
    ],
  },
  {
    path: "attendee",
    canActivate: [AuthGuard],
    data: {
      roles: ["admin", "staff"],
    },
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./main/views/attendee/attendee.module").then(
            (m) => m.AttendeeModule
          ),
      },
      { path: "", redirectTo: "attendee", pathMatch: "full" },
    ],
  },
  {
    path: "check-in",
    canActivate: [AuthGuard],
    data: {
      roles: ["admin", "staff"],
    },
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./main/views/check-in/check-in.module").then(
            (m) => m.CheckInModule
          ),
      },
      { path: "", redirectTo: "check-in", pathMatch: "full" },
    ],
  },
  {
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./main/views/check-in-list/check-in-list.module").then(
            (m) => m.CheckInListModule
          ),
      },
      { path: "", redirectTo: "check-in-list", pathMatch: "full" },
    ],
    data: {
      roles: ["admin", "staff"],
    },
    path: "check-in-list",
  },
  {
    path: "document",
    component: DocsComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./main/views/docs/docs.module").then((m) => m.DocsModule),
      },
      { path: "", redirectTo: "document", pathMatch: "full" },
    ],
  },
  {
    path: "privacy",
    // canActivate: [AuthGuard],
    component: PrivacyComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./main/views/privacy/privacy.module").then(
            (m) => m.PrivacyModule
          ),
      },
      { path: "", redirectTo: "privacy", pathMatch: "full" },
    ],
  },
  {
    path: "pages",
    loadChildren: () =>
      import("./main/pages/pages.module").then((m) => m.PagesModule),
  },
  {
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./main/views/redeem-coupon/redeem-coupon.module").then(
            (m) => m.RedeemCouponModule
          ),
      },
      { path: "", redirectTo: "redeem-coupon", pathMatch: "full" },
    ],
    data: {
      roles: ["admin", "staff"],
    },
    path: "redeem-coupon",
  },
  {
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        loadChildren: () =>
          import(
            "./main/views/redeem-coupon-multi/redeem-coupon-multi.module"
          ).then((m) => m.RedeemCouponMultiModule),
      },
      { path: "", redirectTo: "redeem-coupon-multi", pathMatch: "full" },
    ],
    data: {
      roles: ["admin", "staff"],
    },
    path: "redeem-coupon-multi",
  },
  {
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        loadChildren: () =>
          import(
            "./main/views/redeem-coupon-history/redeem-coupon-history.module"
          ).then((m) => m.RedeemCouponHistoryModule),
      },
      { path: "", redirectTo: "redeem-coupon-history", pathMatch: "full" },
    ],
    data: {
      roles: ["admin", "staff"],
    },
    path: "redeem-coupon-history",
  },
  {
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./main/views/user-temp-code/user-temp-code.module").then(
            (m) => m.UserTempCodeModule
          ),
      },
      { path: "", redirectTo: "user-temp-code", pathMatch: "full" },
    ],
    data: {
      roles: ["admin", "staff"],
    },
    path: "user-temp-code",
  },
  {
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        loadChildren: () =>
          import(
            "./main/views/create-user-temp-code/create-user-temp-code.module"
          ).then((m) => m.CreateUserTempCodeModule),
      },
      { path: "", redirectTo: "create-user-temp-code", pathMatch: "full" },
    ],
    data: {
      roles: ["admin", "staff"],
    },
    path: "create-user-temp-code",
  },
  {
    path: "order-coupon",
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./main/views/order-coupon/order-coupon.module").then(
            (m) => m.OrderCouponModule
          ),
      },
      { path: "", redirectTo: "order-coupon", pathMatch: "full" },
    ],
    data: {
      roles: ["admin", "staff"],
    },
  },
  {
    path: "generate-code-link",
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        loadChildren: () =>
          import(
            "./main/views/generate-code/generate-code-link/generate-code-link.module"
          ).then((m) => m.GenerateCodeLinkModule),
      },
      { path: "", redirectTo: "generate-code-link", pathMatch: "full" },
    ],
    data: {
      roles: ["admin", "staff"],
    },
  },
  {
    path: "qr-code-link-info",
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        loadChildren: () =>
          import(
            "./main/views/qr-code-link-info/qr-code-link-info.module"
          ).then((m) => m.QrCodeLinkInfoModule),
      },
      { path: "", redirectTo: "qr-code-link-info", pathMatch: "full" },
    ],
    data: {
      roles: ["admin", "staff"],
    },
  },
  {
    path: "generate-link-quest",
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        loadChildren: () =>
          import(
            "./main/views/generate-code/generate-link-quest/generate-link-quest.module"
          ).then((m) => m.GenerateLinkQuestModule),
      },
      { path: "", redirectTo: "generate-link-quest", pathMatch: "full" },
    ],
    data: {
      roles: ["admin", "staff"],
    },
  },
  {
    path: "qr-code-link-quest",
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        loadChildren: () =>
          import(
            "./main/views/qr-code-link-quest/qr-code-link-quest.module"
          ).then((m) => m.QrCodeLinkQuestModule),
      },
      { path: "", redirectTo: "qr-code-link-quest", pathMatch: "full" },
    ],
    data: {
      roles: ["admin", "staff"],
    },
  },
  {
    path: "scan-user-qr-to-complete-quest",
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        loadChildren: () =>
          import(
            "./main/views/scan-user-qr-to-complete-quest/scan-user-qr-to-complete-quest.module"
          ).then((m) => m.ScanUserQrToCompleteQuestModule),
      },
      {
        path: "",
        redirectTo: "scan-user-qr-to-complete-quest",
        pathMatch: "full",
      },
    ],
    data: {
      roles: ["admin", "staff"],
    },
  },
  {
    path: "login-with-token/:token",
    // canActivate: [AuthGuard],
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./main/views/login-with-token/login-with-token.module").then(
            (m) => m.LoginWithTokenModule
          ),
      },
      { path: "", redirectTo: "login-with-token", pathMatch: "full" },
    ],
    // data: {
    //     roles: ['admin', 'staff']
    // },
  },
  {
    path: "prints",
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./main/views/prints/prints.module").then(
            (m) => m.PrintsModule
          ),
      },
      { path: "", redirectTo: "prints", pathMatch: "full" },
    ],
    data: {
      roles: ["admin", "staff"],
    },
  },
  {
    path: "print-temp-code",
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        loadChildren: () =>
          import(
            "./main/views/print-temp-user-code/print-temp-user-code.module"
          ).then((m) => m.PrintTempUserCodeModule),
      },
      { path: "", redirectTo: "print-temp-code", pathMatch: "full" },
    ],
    data: {
      roles: ["admin", "staff"],
    },
  },
  {
    path: "scan-qr-to-register",
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        loadChildren: () =>
          import(
            "./main/views/scan-qr-to-register/scan-qr-to-register.module"
          ).then((m) => m.ScanQrToRegisterModule),
      },
      { path: "", redirectTo: "scan-qr-to-register", pathMatch: "full" },
    ],
    data: {
      roles: ["admin", "staff"],
    },
  },
  {
    path: "scan-ticket-id-to-complete-quest",
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        loadChildren: () =>
          import(
            "./main/views/scan-ticket-id-to-complete-quest/scan-ticket-id-to-complete-quest.module"
          ).then((m) => m.ScanTicketIdToCompleteQuestModule),
      },
      {
        path: "",
        redirectTo: "scan-ticket-id-to-complete-quest",
        pathMatch: "full",
      },
    ],
    data: {
      roles: ["admin", "staff"],
    },
  },
  {
    path: "create-qr-payment",
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        loadChildren: () =>
          import(
            "./main/views/create-qr-payment/create-qr-payment.module"
          ).then((m) => m.CreateQrPaymentModule),
      },
      { path: "", redirectTo: "create-qr-payment", pathMatch: "full" },
    ],
    data: {
      roles: ["admin", "staff"],
    },
  },
  {
    path: "**",
    redirectTo: "/pages/miscellaneous/error", // Error 404 - Page not found
  },
];

@NgModule({
  imports: [
    ContentHeaderModule,
    RouterModule.forRoot(routes, { scrollPositionRestoration: "top" }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
