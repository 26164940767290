import { FindAllByPipe } from './find-all-by.pipe';
import { NgModule } from '@angular/core';

import { FilterPipe } from '@core/pipes/filter.pipe';

import { InitialsPipe } from '@core/pipes/initials.pipe';

import { SafePipe } from '@core/pipes/safe.pipe';
import { StripHtmlPipe } from '@core/pipes/stripHtml.pipe';
import { FieldRenderPipe } from './field-render.pipe';


@NgModule({
  declarations: [
    InitialsPipe, 
    FilterPipe, 
    StripHtmlPipe, 
    SafePipe,

    FindAllByPipe,
     FieldRenderPipe,
  ],
  imports: [],
  exports: [
    InitialsPipe,
    FilterPipe,
    StripHtmlPipe,
    SafePipe,

    FindAllByPipe,
    FieldRenderPipe,
  ]
})
export class CorePipesModule {}
