<div class="auth-wrapper auth-v1 px-2">
  <div class="auth-inner py-2">
    <!-- Login v1 -->
    <div class="card mb-0">
      <div class="card-body">
        <a href="javascript:void(0);" class="brand-logo mb-1">
          <img
            src="{{ coreConfig.app.appLogoImage }}"
            alt="brand-logo"
            height="28"
          />
          <h2 class="brand-text text-primary ml-1">
            {{ coreConfig.app.appName }}
          </h2>
        </a>

        <p class="text-center card-text mb-2">Please sign-in to your account</p>

        <div *ngIf="error" class="text-center">
          <p class="text-danger">{{ error }}</p>
        </div>

        <form
          class="auth-login-form mt-2"
          [formGroup]="loginForm"
          (ngSubmit)="onSubmit()"
        >
          <div class="form-group">
            <label for="login-email" class="form-label"
              >Email or Mobile phone</label
            >
            <input
              id="login-email"
              type="text"
              formControlName="email"
              class="form-control"
              placeholder="sociallab@example.com"
              aria-describedby="login-email"
              tabindex="1"
              autofocus
              [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
            />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">Email is required</div>
              <div *ngIf="f.email.errors.email">
                Email must be a valid email address
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label for="login-password">Password</label>
              <a routerLink="/pages/authentication/forgot-password-v1">
                <small>Forgot Password?</small>
              </a>
            </div>
            <div class="input-group input-group-merge form-password-toggle">
              <input
                id="login-password"
                [type]="passwordTextType ? 'text' : 'password'"
                formControlName="password"
                class="form-control form-control-merge"
                tabindex="2"
                placeholder="·········"
                aria-describedby="login-password"
                [ngClass]="{
                  'is-invalid error': submitted && f.password.errors
                }"
              />

              <div class="input-group-append">
                <span class="input-group-text cursor-pointer"
                  ><i
                    class="feather font-small-4"
                    [ngClass]="{
                      'icon-eye-off': passwordTextType,
                      'icon-eye': !passwordTextType
                    }"
                    (click)="togglePasswordTextType()"
                  ></i
                ></span>
              </div>
            </div>
            <div
              *ngIf="submitted && f.password.errors"
              class="invalid-feedback"
              [ngClass]="{ 'd-block': submitted && f.password.errors }"
            >
              <div *ngIf="f.password.errors.required">Password is required</div>
            </div>
          </div>
          <div class="form-group">
            <div class="custom-control custom-checkbox">
              <input
                class="custom-control-input"
                type="checkbox"
                id="remember-me"
                tabindex="3"
              />
              <label class="custom-control-label" for="remember-me">
                Remember Me
              </label>
            </div>
          </div>
          <button class="btn btn-primary btn-block" tabindex="4" rippleEffect>
            Sign in
          </button>

          <hr class="my-2" />

          <div class="d-flex align-items-center justify-content-center">
            <div class="signin-icon-wrapper mx-1">
              <div class="google-button" id="google-button"></div>
              <img
                class="google-signin-icon"
                src="assets/images/social/google.png"
                alt="google"
              />
            </div>

            <div class="signin-icon-wrapper" (click)="signInWithFacebook()">
              <img
                class="facebook-signin-icon mx-1"
                src="assets/images/social/facebook.png"
                alt="facebook"
              />
            </div>
          </div>

          <!--          <hr />

          <button class="btn azure-button btn-block" tabindex="4" rippleEffect>
            <img
                    class="mr-1"
                    width="15"
                    height="15"
                    src="https://cdn-icons-png.flaticon.com/512/732/732225.png"
            />
            Sign in with Azure AD
          </button>

          <button class="btn btn-block" tabindex="4" rippleEffect>
            Sign in with AOT
          </button> -->
        </form>
        <!--
        <p class="text-center mt-2">
          <span>New on our platform?</span>
          <a routerLink="/pages/authentication/register-v1">
            <span>&nbsp;Create an account</span>
          </a>
        </p>

        <div class="divider my-2">
          <div class="divider-text">or</div>
        </div>

        <div class="auth-footer-btn d-flex justify-content-center">
          <a href="javascript:void(0)" class="btn btn-facebook">
            <i data-feather="facebook"></i>
          </a>
          <a href="javascript:void(0)" class="btn btn-twitter white">
            <i data-feather="twitter"></i>
          </a>
          <a href="javascript:void(0)" class="btn btn-google">
            <i data-feather="mail"></i>
          </a>
          <a href="javascript:void(0)" class="btn btn-github">
            <i data-feather="github"></i>
          </a>
        </div>
        -->
        <p></p>
      </div>
    </div>
    <!-- /Login v1 -->
  </div>
</div>
