import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  public contentHeader: object;

  constructor() { }

  ngOnInit() {
    this.contentHeader = {
      headerTitle: 'Dashboard',
      actionButton: false,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'LINX DASHBOARD',
          },
        ]
      }
    };
  }

}
