<div class="content-wrapper">
  <div class="content-body">
      <app-content-header [contentHeader]="contentHeader"></app-content-header>

      <section>
          <div class="row">
              <div class="col-12">
                  <div class="card">
                      <div class="card-header justify-content-center">
                          <h1 class="card-title text-center title-linx-dashboard">LINX DASHBOARD</h1>
                      </div>
                      <!-- <div class="card-body pb-2">
                          <p class="card-text">Start building your smart contract using LINX DASHBOARD.</p>
                      </div> -->
                  </div>
              </div>
              <div class="col-12">
                  <div class="card">
                      <div class="card-body">
                        <h3>10 Latest Check in</h3>
                      </div>
                      <app-check-in-history [amount]="10" ></app-check-in-history>
                  </div>
              </div>
          </div>
      </section>

  </div>
</div>
