export const locale = {
  lang: "th",
  data: {
    MENU: {
      APP_TITLE: "Linx Dashboard",
      ATTENDEE: "ผู้เข้าร่วม",
      ATTENDEE_SEARCH: "ค้นหาผู้เข้าร่วม",
      CHECK_IN: "ลงชื่อเข้างาน",
      CHECK_IN_HISTORY: "ประวัติการลงชื่อเข้างาน",
      DASHBOARD: "แดชบอร์ด",
      DOCUMENT: "คู่มือการใช้งาน",
      HOME: "หน้าแรก",
      REDEEM_COUPON: "ใช้งานคูปอง",
      REDEEM_COUPON_MULTI: "ใช้งานคูปองหลายใบ",
      REDEEM_HISTORY: "ประวัติการใช้คูปอง",
      USER_TEMP_CODE: "รหัสผู้ใช้ชั่วคราว",
      CREATE_USER_TEMP_CODE: "สร้างรหัสผู้ใช้ชั่วคราว",
      ORDER_COUPON: "คูปองออเดอร์",
      GENERATE_CODE_LINK: "สร้าง โค๊ด ลิงค์",
      QR_CODE_LINK_INFO: "ข้อมูลลิงก์ของ QR Code",
      GENERATE_LINK_QUEST: "สร้าง ลิงค์ เควส",
      QR_CODE_LINK_QUEST: "QR Code Link Quest",
      SCAN_USER_QR_TO_COMPLETE_QUEST: "สแกน QR ผู้ใช้เพื่อจบเควส",
      CREATE_QR_PAYMENT: "สร้าง QR ชำระเงิน",
      SAMPLE: "ตัวอย่าง",
      SCAN_QR_TO_REGISTER: "สแกน QR เพื่อสมัครบัญชี",
      SECTIONS: {
        GENERAL: "General",
        OPERATION: "Operation",
      },
    },
    SELECT_FILTER: {
      NEWEST: "ใหม่สุด",
      OLDEST: "เก่าสุด",
    },
  },
};
