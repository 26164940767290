export const locale = {
  lang: 'en',
  data: {
    MENU: {
      APP_TITLE: 'LINX DASHBOARD',
      ATTENDEE: 'Attendee',
      ATTENDEE_SEARCH: 'Search Attendee',
      CHECK_IN: 'Check In',
      CHECK_IN_HISTORY: 'Check in History',
      DASHBOARD: 'Dashboard',
      DOCUMENT: 'Document',
      HOME: 'Home',
      REDEEM_COUPON: 'Redeem Coupon',
      REDEEM_COUPON_MULTI: 'Redeem Coupon Multi',
      REDEEM_HISTORY: 'Redeem History',
      USER_TEMP_CODE: 'User Temp Code',
      CREATE_USER_TEMP_CODE: 'Create User Temp Code',
      ORDER_COUPON: 'Order Coupon',
      GENERATE_CODE_LINK: 'Generate code link',
      QR_CODE_LINK_INFO: 'QR Code Link Info',
      GENERATE_LINK_QUEST: 'Generate link quest',
      QR_CODE_LINK_QUEST: 'QR Code Link Quest',
      SCAN_USER_QR_TO_COMPLETE_QUEST: 'Scan User QR to Complete Quest',
      CREATE_QR_PAYMENT: 'Create QR Payment',
      SCAN_QR_TO_REGISTER: 'Scan QR to Register',
      SAMPLE: 'Sample',
      SECTIONS: {
        GENERAL: 'General',
        OPERATION: 'Operation'
      }
    },
    SELECT_FILTER: {
      NEWEST: 'newest',
      OLDEST: 'oldest',
    }
  }
};
