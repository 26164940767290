
<div class="account-list-wrapper mt-auto bg-light-dark border-top" *ngIf="currentEvent">
    <div class="account-list-inner">
        <div class="account-list-select">
            <div *ngIf="loading" class="event-loading" >
                <ngx-skeleton-loader [theme]="{height: '32px'}"></ngx-skeleton-loader>
            </div>
            <div  *ngIf="!loading" ngbDropdown class="btn-select-account-wrapper d-inline-block w-100 shadow">

                <button type="button" class="btn-select-account btn border w-100" id="dropdown-account-list" ngbDropdownToggle>
                    <!--<img src="../../../../assets/images/wallets/metamask.png" width="30" class="mr-50"> -->
                    <span>{{currentEvent.name}}</span>
                </button>
                <ul class="sub-dropdown-account-list" ngbDropdownMenu aria-labelledby="dropdown-account-list">
                    <div class="event-detail">
                        <p>You are currently on</p>
                        <h5>{{currentEvent.name}}</h5>
                    </div>

                    <hr/>

                    <li class="sub-dropdown-account-item px-2 py-50">
                        <a class="d-block w-100">
                            <span>Preference</span>
                        </a>
                    </li>
                    <li class="sub-dropdown-account-item px-2 py-50">
                        <a class="d-block w-100">
                            <!--<img src="../../../../assets/images/wallets/metamask.png" width="30" class="mr-50">-->
                            <span>Switch event</span>
                        </a>
                        <ul style="display: none;">

                            <li *ngFor="let event of events">
                                <a class="px-2 py-50">
                                    {{event.name}}
                                    <i *ngIf="currentEvent.id == event.id" data-feather="check"></i>
                                </a>
                            </li>
                        </ul>
                    </li>

                </ul>
            </div>
        </div>
    </div>
</div>
