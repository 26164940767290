// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hmr: false, // vuexy default config

  apiUrl: "https://api.dev.linxlab.io",

  googleKey: "AIzaSyAiNIrc7oxX6OlpFds-sKP-r-WEyUo4MGE",
  googleClientID:
    "912232120931-0hjmaebm41apo2lq6jv8a5hdlu0cnr6b.apps.googleusercontent.com",
  facebookId: "192898523460682",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
