import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventMenuComponent } from './event-menu/event-menu.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import { CoreCommonModule } from '@core/common.module';



@NgModule({
  declarations: [EventMenuComponent],
  exports: [
    EventMenuComponent
  ],
  imports: [
    CommonModule,
    CoreCommonModule,
    NgbModule,
    NgxSkeletonLoaderModule
  ]
})
export class EventModule { }
