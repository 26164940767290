import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
Router
@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {

  public contentHeader: object

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'Privacy',
      actionButton: false,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'privacy policy terms of service.',

          },
        ]
      }
    }
  }

  openPopupSubcription() {
    Swal.fire({
      title: 'คุณต้องการสมัครสมาชิกไหม?',
      // text: "เรามีโปรโมชั่นสำหรับคุณ",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#c9c9c9',
      confirmButtonText: 'Yes',
      cancelButtonText: 'Close',
      allowOutsideClick: false,
      allowEscapeKey:  false
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['/subscription'])
      } else {
        this.router.navigate(['/home'])
      }
    })
  }

}
