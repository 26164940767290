import { Component, OnInit } from '@angular/core';
import {BackendService} from '../../../../../services/backend.service';
import { Logger } from '../../../../../services/logger.service';
import {EventService} from '../../../../../services/event.service';
import {AuthenticationService} from '../../../../../auth/service';
const log = new Logger('EventMenuComponent');
@Component({
  selector: 'app-event-menu',
  templateUrl: './event-menu.component.html',
  styleUrls: ['./event-menu.component.scss']
})
export class EventMenuComponent implements OnInit {

  loading = true;
  currentEvent: any;
  events = [];

  constructor(private authService: AuthenticationService, private backendService: BackendService, private eventService: EventService) { }

  async ngOnInit() {
    this.loading = true;
    this.authService.currentUser.subscribe({
      next: async (user) => {
        this.events = await this.backendService.getEvents();
        this.currentEvent = await this.eventService.getCurrentEvent();
        log.debug(`Fetching events`);
        log.debug(this.events);
        this.loading = false;
      },
      error(msg) {
        console.log('Error Getting Location: ', msg);
      }
    });

  }

}
