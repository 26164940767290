import { Pipe, PipeTransform } from '@angular/core';

import { Logger } from './../../app/services/logger.service';
const log = new Logger('FindAllByPipe'); // log.debug

@Pipe({
  name: 'findAllBy'
})
export class FindAllByPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    let fieldName:any = args[0]

    log.debug("value => %o",value)
    log.debug("fieldName => %o",fieldName)

    let x = value.filter(it => it[fieldName])
    log.debug("xxxxxxxxxxxxxxxxxxxxxxxxxxxx => %o",x)
    return x;
  }

}
