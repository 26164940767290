import {Component, Input, OnInit} from '@angular/core';
import {EventService} from '../../services/event.service';
import { Logger } from '../../services/logger.service';
const log = new Logger('CheckInHistoryComponent');

@Component({
  selector: 'app-check-in-history',
  templateUrl: './check-in-history.component.html',
  styleUrls: ['./check-in-history.component.scss']
})
export class CheckInHistoryComponent implements OnInit {

  @Input() amount = 10;
  @Input() sortField = 'updatedAt';
  @Input() sort = 'desc';
  @Input() isPagination?: boolean;

  isLoading = true;
  history: any = [];
  totalResults: number;
  page = 1;

  columns = [
    { name: 'Stamp', prop: 'attendanceDate' },
    { name: 'Stamp By', prop: 'stampBy.email' },
    { name: 'First name', prop: 'user.firstName' },
    { name: 'Last name', prop: 'user.lastName' },
    { name: 'Email', prop: 'user.email' },
    { name: 'Mobile', prop: 'user.mobilePhone' },
  ];

  constructor(private _eventService: EventService) {
  }

  async ngOnInit() {
    await this.fetchData();
  }

  async fetchData() {
    this.isLoading = true;

    const data = await this._eventService.getCheckInHistory(this.amount, this.page, this.sortField + ':' + this.sort);
    this.history = data.results;
    this.totalResults = data.totalResults;
    log.debug('History retrieved : ', this.history);
    this.isLoading = false;
  }

  async onPageChange(event: any) {
    this.page = event.offset + 1;
    this.amount = event.limit;
    await this.fetchData();
  }

}
