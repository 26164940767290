import { async } from '@angular/core/testing';
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { environment } from "environments/environment";
import { User, Role } from "app/auth/models";
import { ToastrService } from "ngx-toastr";
import { EventService } from "../../services/event.service";
import { Logger } from "app/services/logger.service";

const log = new Logger('BackendService');
@Injectable({ providedIn: "root" })
export class AuthenticationService {
  // public
  public currentUser: Observable<User>;

  // private
  public currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(
    private _http: HttpClient,
    private _toastrService: ToastrService
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return (
      this.currentUser && this.currentUserSubject.value.role === Role.Admin
    );
  }

  /**
   *  Confirms if user is client
   */
  get isStaff() {
    return (
      this.currentUser && this.currentUserSubject.value.role === Role.Staff
    );
  }

  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */
  login(email: string, password: string) {
    return this._http
      .post<any>(`${environment.apiUrl}/v1/wallet-server/auth/login`, {
        username: email,
        password,
      })
      .pipe(
        map((result) => {
          return this.setUserLogin(result);
        })
      );
  }

  async refreshTokenPromise(refreshToken: any) {
    const url = `${environment.apiUrl}/v1/wallet-server/auth/refresh-tokens`;
    return new Promise(async (resolve, reject) => {
      try {
        const data = {
          refreshToken: refreshToken,
        };
        log.debug('refreshTokenPromise url,data : %o,%o', url, data);
        let tokens: any = await this._http.post(url, data).toPromise();
        log.debug('refreshTokenPromise tokens %o', tokens);
        if (tokens) {
          // await this.updateTokens(tokens)
          let user: any = JSON.parse(await localStorage.getItem("currentUser"));
          user.refreshToken = tokens.refresh.token
          user.token = tokens.access.token
          await localStorage.setItem("currentUser", JSON.stringify(user));

          resolve(tokens);
        } else {
          resolve(null);
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  async loginWithWalletToken(walletToken) {
    try {
        const url = `${environment.apiUrl}/v1/wallet-server/users/token/${walletToken}`;
        const result: any = await this._http.get(url).toPromise();
        await this.setUserLogin(result)
        log.debug('fetch loginWithWalletToken result : ', result);
        return result;
    } catch (error) {
        log.error('error on loginWithWalletToken', error);
        return undefined;
    }
  }

  async loginWithGoogle(authToken: string) {
    const body = {
      idToken: authToken,
    };
    try {
      const result = await this._http
        .post<any>(
          `${environment.apiUrl}/v1/wallet-server/auth/google-fetcher`,
          body
        )
        .toPromise();

      this.setUserLogin(result);
    } catch (error) {
      throw error;
    }
  }

  async loginWithFacebook(authToken: string) {
    const body = {
      accessToken: authToken,
    };
    try {
      const result = await this._http
        .post<any>(
          `${environment.apiUrl}/v1/wallet-server/auth/facebook-fetcher`,
          body
        )
        .toPromise();

      this.setUserLogin(result);
    } catch (error) {
      throw error;
    }
  }

  private setUserLogin(result: any) {
    // login successful if there's a jwt token in the response
    if (result.user && result.tokens) {
      // TODO : Check role if user check event role
      // TODO : Remove this
      //console.log(result.user.role)
      if (result.user.role === "user") {
        result.user.role = "staff";
      }
      result.user.token = result.tokens.access.token;
      result.user.refreshToken = result.tokens.refresh.token;
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem("currentUser", JSON.stringify(result.user));
      // notify
      this.currentUserSubject.next(result.user);
    }
    return result.user;
  }

  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    // notify
    this.currentUserSubject.next(null);
  }

  async updateRole(role) {
    const user: any = JSON.parse(await localStorage.getItem("currentUser"));
    user.role = role;
    await localStorage.setItem("currentUser", JSON.stringify(user));
    this.currentUserSubject.next(user);
  }
}
