import { CoreMenu } from "@core/types";

export const menu: CoreMenu[] = [
  {
    id: "general",
    type: "section",
    title: "General",
    translate: "MENU.SECTIONS.GENERAL",
    children: [
      {
        id: "dashboard",
        title: "Dashboard",
        translate: "MENU.DASHBOARD",
        type: "item",
        icon: "home",
        url: "dashboard",
      },
      {
        id: "attendee",
        title: "Attendee",
        translate: "MENU.ATTENDEE",
        role: ["admin"],
        type: "item",
        icon: "user",
        url: "attendee/list",
      },
      {
        id: "search",
        title: "Search Attendee",
        translate: "MENU.ATTENDEE_SEARCH",
        role: ["admin"],
        type: "item",
        icon: "search",
        url: "attendee/search",
      },
      {
        id: "checkin-list",
        title: "Check in History",
        translate: "MENU.CHECK_IN_HISTORY",
        role: ["admin"],
        type: "item",
        icon: "user-check",
        url: "check-in-list",
      },
      {
        id: "redeem-history",
        title: "Redeem History",
        translate: "MENU.REDEEM_HISTORY",
        role: ["admin"],
        type: "item",
        icon: "check-square",
        url: "redeem-coupon-history",
      },
      {
        id: "user-temp-code",
        title: "User Temp Code",
        translate: "MENU.USER_TEMP_CODE",
        role: ["admin"],
        type: "item",
        icon: "users",
        url: "user-temp-code",
      },
      {
        id: "create-user-temp-code",
        title: "Create User Temp Code",
        translate: "MENU.CREATE_USER_TEMP_CODE",
        role: ["admin"],
        type: "item",
        icon: "user-plus",
        url: "create-user-temp-code",
      },
      {
        id: "create-qr-payment",
        title: "Create QR Payment",
        translate: "MENU.CREATE_QR_PAYMENT",
        role: ["admin"],
        type: "item",
        icon: "credit-card",
        url: "create-qr-payment",
      },
    ],
  },
  {
    id: "operation",
    type: "section",
    title: "Operation",
    translate: "MENU.SECTIONS.OPERATION",
    children: [
      {
        id: "checkin",
        title: "Check in",
        translate: "MENU.CHECK_IN",
        type: "item",
        icon: "check",
        url: "check-in",
        requireOperation: "isCheckInAble",
      },
      {
        id: "scan-qr-to-register",
        title: "Scan QR to Register",
        translate: "MENU.SCAN_QR_TO_REGISTER",
        type: "item",
        icon: "maximize",
        url: "scan-qr-to-register",
        requireOperation: "isCheckInAble",
      },
      {
        id: "redeem",
        title: "Redeem Coupon",
        translate: "MENU.REDEEM_COUPON",
        type: "item",
        icon: "maximize",
        url: "redeem-coupon",
        requireOperation: "isRedeemCouponAble",
      },
      {
        id: "redeem-multi",
        title: "Redeem Coupon Multi",
        translate: "MENU.REDEEM_COUPON_MULTI",
        type: "item",
        icon: "layers",
        url: "redeem-coupon-multi",
        requireOperation: "isRedeemCouponAble",
      },
      {
        id: "order-coupon",
        title: "Order Coupon",
        translate: "MENU.ORDER_COUPON",
        type: "item",
        icon: "clipboard",
        url: "order-coupon",
        requireOperation: "isCompleteOrderCouponAble",
      },
      {
        id: "generate-code-link",
        title: "Generate Code Link",
        translate: "MENU.ORDER_COUPON",
        type: "item",
        icon: "code",
        url: "generate-code-link",
        requireOperation: "isCompleteOrderCouponAble",
      },
      {
        id: "qr-code-link-info",
        title: "QR Code Link Info",
        translate: "MENU.QR_CODE_LINK_INFO",
        type: "item",
        icon: "minus-square",
        url: "qr-code-link-info",
        requireOperation: "isCompletedQuestAble",
      },
      {
        id: "generate-link-quest",
        title: "Generate Link Quest",
        translate: "MENU.ORDER_COUPON",
        type: "item",
        icon: "code",
        url: "generate-link-quest",
        requireOperation: "isCompletedQuestAble",
      },
      {
        id: "qr-code-link-quest",
        title: "QR Code Link Quest",
        translate: "MENU.QR_CODE_LINK_QUEST",
        type: "item",
        icon: "minus-square",
        url: "qr-code-link-quest",
        requireOperation: "isCompletedQuestAble",
      },
      {
        id: "scan-user-qr-to-complete-quest",
        title: "Scan User QR to Complete Quest",
        translate: "MENU.SCAN_USER_QR_TO_COMPLETE_QUEST",
        type: "item",
        icon: "maximize",
        url: "scan-user-qr-to-complete-quest",
        requireOperation: "isCompletedQuestAble",
      },
      {
        id: "scan-ticket-id-to-complete-quest",
        title: "Scan Ticket ID to Complete Quest",
        translate: "MENU.SCAN_QR_TO_REGISTER",
        type: "item",
        icon: "maximize",
        url: "scan-ticket-id-to-complete-quest",
        requireOperation: "isCompletedQuestAble",
      },
    ],
  },
  // {
  //   id: 'home',
  //   title: 'Home',
  //   translate: 'MENU.HOME',
  //   type: 'item',
  //   icon: 'home',
  //   url: 'home'
  // },
  // {
  //   id: 'sample',
  //   title: 'Sample',
  //   translate: 'MENU.SAMPLE',
  //   type: 'item',
  //   icon: 'file',
  //   url: 'sample'
  // }
  // {
  //   id: 'document',
  //   title: 'Document',
  //   translate: 'MENU.DOCUMENT',
  //   type: 'item',
  //   icon: 'file',
  //   url: 'document'
  // },
  // {
  //   id: 'LINX DASHBOARD',
  //   title: 'LINX DASHBOARD',
  //   translate: 'MENU.APP_TITLE',
  //   type: 'item',
  //   icon: 'file',
  //   url: 'LINX DASHBOARD'
  // },
];
