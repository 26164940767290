import { Injectable } from '@angular/core';
import {BackendService} from './backend.service';
import {Subject} from 'rxjs';

import {User} from '../auth/models';
import {AuthenticationService} from '../auth/service';
import { Logger } from './logger.service';
const log = new Logger('EventService');

@Injectable({
  providedIn: 'root'
})
export class EventService {
  currentUser: User;
  currentEventRole;
  currentEvent;
  currentStaffOperations;
  isInit = false;

  constructor(private backendService: BackendService, private _authenticationService: AuthenticationService) {
    this._authenticationService.currentUser.subscribe(x => (this.currentUser = x));
    this.init();
  }

  async init() {
    log.debug('Init event');
    const events = await this.backendService.getEvents();
    this.currentEvent = events.length > 0 ? events[0] : undefined;
    this.isInit = true;
  }

  async getCurrentEvent() {
    if (!this.isInit) {
      await this.init();
    }
    return this.currentEvent;
  }

  async changeCurrentEvent(event) {
    this.currentEvent = event;
    window.location.reload();
  }

  async getAttendeesByEvent(eventId, limit = 10, page = 1) {
    return this.backendService.getAttendeesByEvent(eventId, limit, page);
  }

  async getUserRoleByEvent(force = false) {
    if (force || (this.currentUser && !this.currentEventRole)) {
      const event = await this.getCurrentEvent();
      log.debug("xxxx getUserRoleByEvent this.currentUser => %o",this.currentUser)
      const info = await this.backendService.getUserInfoByEvent(event.id);
      this.currentEventRole = info.role;
     // await this._authenticationService.updateRole(this.currentEventRole);
    }
    return this.currentEventRole;
  }
  async getUserRoleAbleByEvent(force = false) {
    if (force || (this.currentUser && !this.currentEventRole)) {
      const event = await this.getCurrentEvent();
      log.debug("yyyy getUserRoleByEvent this.currentUser => %o",this.currentUser)
      const info = await this.backendService.getUserInfoByEvent(event.id);
      this.currentStaffOperations = info.staffOperations;
      // await this._authenticationService.updateRole(this.currentEventRole);
    }
    return this.currentStaffOperations;
  }

  async getUserTokenBalances(userId: string) {
    return this.backendService.getUserTokenBalancesByEvent(userId, this.currentEvent.code);
  }

  async getCheckInHistory(amount: number, page: number, sort: string = 'desc') {
    const event = await this.getCurrentEvent();
    return this.backendService.getCheckInHistory(event.id, amount, page, sort);
  }

  async getUserQuestionnaire(userId: string) {
    return this.backendService.getQuestionnaireByEvent(userId, this.currentEvent.code);
  }
}
