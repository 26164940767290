import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgZone, OnInit, ViewEncapsulation} from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import {EventService} from '../../../app/services/event.service';
import {AuthenticationService} from '../../../app/auth/service';

@Component({
  selector: '[core-menu]',
  templateUrl: './core-menu.component.html',
  styleUrls: ['./core-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoreMenuComponent implements OnInit {
  currentUser: any;
  currentEventRole = '-';
  currentStaffOperations;

  @Input()
  layout = 'vertical';

  @Input()
  menu: any;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   *
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {CoreMenuService} _coreMenuService
   * @param _eventService
   * @param _authenticationService
   */
  constructor(
      private _changeDetectorRef: ChangeDetectorRef,
      private _coreMenuService: CoreMenuService,
      private _eventService: EventService,
      private _authenticationService: AuthenticationService,
      private _zone: NgZone
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  async ngOnInit() {
    this._authenticationService.currentUser.subscribe(x => (this.currentUser = x));

    this.currentEventRole = await this._eventService.getUserRoleByEvent();
    this.currentStaffOperations = await this._eventService.getUserRoleAbleByEvent(true);

    // await this._eventService.getUserRoleByEvent(true);
    // Set the menu either from the input or from the service
    this.menu = this.menu || this._coreMenuService.getCurrentMenu();

    // Subscribe to the current menu changes
    this._coreMenuService.onMenuChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      this.currentUser = this._coreMenuService.currentUser;

      // Load menu
      this.menu = this._coreMenuService.getCurrentMenu();

      this._changeDetectorRef.markForCheck();

    });

  }
}
