import { Injectable } from "@angular/core";
import { Logger } from "./logger.service";
import { SingletonService } from "./singleton.service";

const log = new Logger("BackendService");
import { environment } from "environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Subject } from "rxjs";
import { promise } from "protractor";

@Injectable({
  providedIn: "root",
})
export class BackendService {
  public getCoupon$ = new Subject<any>();

  constructor(
    private http: HttpClient,
    private singletonService: SingletonService
  ) {}

  async getEvents() {
    try {
      const url = `${environment.apiUrl}/v1/platform-event/events`;
      const res: any = await this.http.get(url).toPromise();
      log.debug("fetch get events from backend result : ", res);
      return res.results;
    } catch (error) {
      log.error("error on get events ", error);
      return [];
    }
  }

  async getUserInfoByEvent(eventId) {
    try {
      const url = `${environment.apiUrl}/v1/wallet-server/users/event/${eventId}`;
      const res: any = await this.http.get(url).toPromise();
      log.debug("fetch get user info by event from backend result : ", res);
      return res;
    } catch (error) {
      log.error("error on get user info by event", error);
      return undefined;
    }
  }

  async getAttendeesByEvent(eventId, limit = 10, page = 1) {
    try {
      const url = `${environment.apiUrl}/v1/platform-event/events/all-users-by-event/${eventId}?limit=${limit}&page=${page}`;
      const res: any = await this.http.get(url).toPromise();
      log.debug("fetch get attendees from backend result : ", res);
      return res;
    } catch (error) {
      log.error("error on get attendee by event ", error);
      return [];
    }
  }

  async searchAttendeesByEvent(
    eventId: string,
    keyword: string,
    limit = 50,
    page = 1
  ) {
    try {
      const url = `${environment.apiUrl}/v1/platform-event/event-users/search/${eventId}?keyword=${keyword}&limit=${limit}&page=${page}`;
      const res: any = await this.http.get(url).toPromise();
      log.debug("fetch search attendees from backend result : ", res);
      return res;
    } catch (error) {
      log.error("error on search attendee by event ", error);
      return [];
    }
  }

  async getAttendeeInfo(userId) {
    try {
      const url = `${environment.apiUrl}/v1/wallet-server/users/${userId}`;
      const res: any = await this.http.get(url).toPromise();
      log.debug("fetch get attendee info from backend result : ", res);
      return res;
    } catch (error) {
      log.error("error on get attendee info ", error);
      return undefined;
    }
  }

  async getAttendeeInfoByEvent(userId, eventId) {
    try {
      const url = `${environment.apiUrl}/v1/platform-event/event-users/tickets?eventId=${eventId}&userId=${userId}`;
      const res: any = await this.http.get(url).toPromise();
      log.debug("fetch get attendee info from backend result : ", res);
      if (res.results && res.results.length > 0) {
        return res.results[0];
      } else {
        return undefined;
      }
    } catch (error) {
      log.error("error on get attendee info ", error);
      return undefined;
    }
  }

  async getUserTokenBalancesByEvent(userId: string, eventCode: string) {
    try {
      const url = `${environment.apiUrl}/v1/platform-event/event-user-tokens/balance/${userId}?eventCode=${eventCode}`;
      const res: any = await this.http.get(url).toPromise();
      log.debug("fetch get user balances from backend result : ", res);
      return res;
    } catch (error) {
      log.error("error on get user balances ", error);
      return [];
    }
  }

  

  async getTicketByTicketId(ticketId: string) {
    try {
      const url = `${environment.apiUrl}/v1/platform-event/event-tickets/${ticketId}`;
      const res: any = await this.http.get(url).toPromise();
      log.debug("fetch getAttendeeByTicketId from backend result : ", res);
      return res;
    } catch (error) {
      log.error("error on getAttendeeByTicketId ", error);
      return undefined;
    }
  }

  async postAttendeeCheckin(ticketId) {
    try {
      const url = `${environment.apiUrl}/v1/platform-event/event-attendances/stamp/${ticketId}`;
      const res: any = await this.http.post(url, {}).toPromise();
      log.debug("post attendee checkin backend result : ", res);
      return res;
    } catch (error) {
      log.error("error on post attendee checkin ", error);
      return undefined;
    }
  }

  async createQRPayment(amount) {
    const data = {
      amount: amount,
      type: "payment"
    }
    try {
      const url = `${environment.apiUrl}/v1/wallet-server/wallet/coupon/purchases`;
      const res: any = await this.http.post(url, data).toPromise();
      log.debug("createQRPayment res : ", res);
      return res;
    } catch (error) {
      log.error("error on createQRPayment ", error);
      return undefined;
    }
  }

  async checkPurchaseTransaction(purchaseId) {
    try {
      const url = `${environment.apiUrl}/v1/wallet-server/wallet/coupon/purchase/check/${purchaseId}`;
      const res: any = await this.http.post(url, {}).toPromise();
      log.debug("createQRPayment res : ", res);
      return res;
    } catch (error) {
      log.error("error on createQRPayment ", error);
      return undefined;
    }
  }

  async getCheckInHistory(
    eventId: string,
    amount: number,
    page: number,
    sort: string = "desc"
  ) {
    try {
      // const url = `${environment.apiUrl}/v1/platform-event/event-attendances/?event=${eventId}?limit=${amount}&order=${sort}`;
      const url = `${environment.apiUrl}/v1/platform-event/event-attendances/?event=${eventId}&limit=${amount}&page=${page}&sortBy=${sort}`;
      const res: any = await this.http.get(url).toPromise();
      log.debug("fetch get checkin histories from backend result : ", res);
      return res;
    } catch (error) {
      log.error("error on checkin histories by event ", error);
      return [];
    }
  }

  async getUseCouponHistory(
    amount: number,
    page: number,
    sort: string = "desc"
  ) {
    try {
      // const url = `${environment.apiUrl}/v1/platform-event/event-attendances/?event=${eventId}?limit=${amount}&order=${sort}`;
      const url = `${environment.apiUrl}/v1/staff-server/coupons/transactions/get-all-use-coupons/?limit=${amount}&page=${page}`;
      const res: any = await this.http.get(url).toPromise();
      log.debug("fetch get use coupon histories from backend result : ", res);
      return res;
    } catch (error) {
      log.error("error on use coupon histories ", error);
      return [];
    }
  }

  async getCoupon(couponCode: string) {
    try {
      const url = `${environment.apiUrl}/v1/platform-event/event-user-coupons/code/${couponCode}`;
      const res: any = await this.http.get(url).toPromise();
      log.debug("fetch get coupon from backend result : ", res);
      return res;
    } catch (error) {
      log.error("error on get coupon by event ", error);
      return undefined;
    }
  }

  async checkQuestCompleted(executeCode: string) {
    try {
      const data = {
        executeCode: executeCode, // "6425435959b5f0acda3155ef:64685cd0dbc229067db64212"
      };
      const url = `${environment.apiUrl}/v1/activity-event/quests/check-quest-completed`;
      const res: any = await this.http.post(url, data).toPromise();
      log.debug("fetch backend result : ", res);
      return res;
    } catch (error) {
      log.error("error on by event ", error);
      throw error;
    }
  }

  async registerByCodeAndMobilePhone(code: any, mobilePhone: any) {
    const data = {
      code: code,
      mobilePhone: mobilePhone,
    };
    const url = `${environment.apiUrl}/v1/staff-server/event-user-temps/register`;
    const res: any = await this.http.post(url, data).toPromise();
    log.debug("fetch registerByCodeAndMobilePhone from backend result : ", res);
    return res;
  }

  async useCoupon(couponId: string) {
    try {
      const url = `${environment.apiUrl}/v1/activity-event/coupons/use/${couponId}`;
      const res: any = await this.http.post(url, {}).toPromise();
      log.debug("post use coupon from backend result : ", res);
      return res;
    } catch (error) {
      log.error("error on use coupon by event ", error);
      return { error: error };
    }
  }

  async createCouponOrder(data) {
    try {
      const url = `${environment.apiUrl}/v1/activity-event/coupon-orders/create`;
      const res: any = await this.http.post(url, data).toPromise();
      log.debug("post create coupon order from backend result : ", res);
      return res;
    } catch (error) {
      log.error("error on  create coupon order  by event ", error);
      return { error: error };
    }
  }

  async generateCode(data) {
    try {
      const url = `${environment.apiUrl}/g/i/generate-code`;
      const res: any = await this.http.post(url, data).toPromise();
      log.debug("post generateCode from backend result : ", res);
      return res;
    } catch (error) {
      log.error("error create generateCode", error);
      return { error: error };
    }
  }

  async generateCodeLinkQuest(data): Promise<any> {
    try {
      const url = `${environment.apiUrl}/g/q/generate-code`;
      const res: any = await this.http.post(url, data).toPromise();
      log.debug("post generateCode  Link Quest from backend result : ", res);
      return res;
    } catch (error) {
      log.error("error create generateCode Link Quest", error);
      return { error: error };
    }
  }

  // Event Quest //
  getEventQuest({
    lang = null,
    eventCode = null,
    page = 1,
    limit = 10,
  }): Promise<any> {
    const url = `${environment.apiUrl}/v1/platform-event/event-quests`;
    let params = new HttpParams();
    if (lang) {
      params = params.set("lang", lang);
    }
    if (eventCode) {
      params = params.set("eventCode", eventCode);
    }
    if (page) {
      params = params.set("page", page.toString());
    }
    if (limit) {
      params = params.set("limit", limit.toString());
    }
    return this.http
      .get(url, { params })
      .toPromise()
      .then((response) => response);
  }

  getEventQuestAllowStaff(eventId: string): Promise<any> {
    const url = `${environment.apiUrl}/v1/staff-server/allowed/event-quests/${eventId}?limit=9999&page=1`;
    return this.http
      .get(url)
      .toPromise()
      .then((response) => response);
  }

  // Event Quest //

  // Coupon Order //
  getCouponOrderByEvent({
    eventCode = null,
    categories = null,
    isCompleted = null,
    sortBy = null,
    page = 1,
    limit = 10,
  }): Promise<any> {
    const url = `${environment.apiUrl}/v1/staff-server/event-coupon-orders/${eventCode}`;
    let params = new HttpParams();
    if (categories) {
      params = params.set("categories", categories);
    }
    if (isCompleted !== null) {
      params = params.set("isCompleted", isCompleted);
    }
    if (sortBy) {
      params = params.set("sortBy", sortBy);
    }
    if (page) {
      params = params.set("page", page.toString());
    }
    if (limit) {
      params = params.set("limit", limit.toString());
    }
    return this.http
      .get(url, { params })
      .toPromise()
      .then((response) => response);
  }

  async completeCouponOrder(paramId: string, data: object): Promise<any> {
    const url = `${environment.apiUrl}/v1/activity-event/coupon-orders/completed/${paramId}`;
    return this.http
      .post(url, data)
      .toPromise()
      .then((response) => response);
  }

  async cancelCouponOrder(paramId: string): Promise<any> {
    const url = `${environment.apiUrl}/v1/activity-event/coupon-orders/cancel/${paramId}`;
    return this.http
      .post(url, {})
      .toPromise()
      .then((response) => response);
  }

  // Coupon Order //

  // QR Code Link Info //
  getQrCodeLinkInfoByEvent({
    eventCode = null,
    refModel = null,
    keyword = null,
    sortBy = null,
    page = 1,
    limit = 10,
  }): Promise<any> {
    const url = `${environment.apiUrl}/v1/platform-event/event-shorten-link-infos`;
    let params = new HttpParams();
    if (eventCode) {
      params = params.set("eventCode", eventCode);
    }
    if (refModel) {
      params = params.set("refModel", refModel);
    }
    if (keyword) {
      params = params.set("keyword", keyword);
    }
    if (sortBy) {
      params = params.set("sortBy", sortBy);
    }
    if (page) {
      params = params.set("page", page.toString());
    }
    if (limit) {
      params = params.set("limit", limit.toString());
    }
    // console.debug('params', params);
    return this.http
      .get(url, { params })
      .toPromise()
      .then((response) => response);
  }

  // QR Code Link Info //

  // QR Code Link Quest //
  getQrCodeLinkQuestByEvent({
    event = null,
    code = null,
    eventQuest = null,
    sortBy = null,
    page = 1,
    limit = 10,
  }): Promise<any> {
    const url = `${environment.apiUrl}/v1/platform-event/event-shorten-link-quests`;
    let params = new HttpParams();
    if (event) {
      params = params.set("event", event);
    }
    if (code) {
      params = params.set("code", code);
    }
    if (eventQuest) {
      params = params.set("eventQuest", eventQuest);
    }
    if (sortBy) {
      params = params.set("sortBy", sortBy);
    }
    if (page) {
      params = params.set("page", page.toString());
    }
    if (limit) {
      params = params.set("limit", limit.toString());
    }
    // console.debug('params', params);
    return this.http
      .get(url, { params })
      .toPromise()
      .then((response) => response);
  }
  getQrCodeLinkQuestByEventAllowStaff({
    eventId = null,
    code = null,
    eventQuest = null,
    sortBy = null,
    page = 1,
    limit = 10,
  }): Promise<any> {
    const url = `${environment.apiUrl}/v1/staff-server/event-shorten-link-quests/${eventId}`;
    let params = new HttpParams();
    // if (event) {
    //     params = params.set('event', event);
    // }
    // if (code) {
    //     params = params.set('code', code);
    // }
    if (eventQuest) {
      params = params.set("eventQuest", eventQuest);
    }
    if (sortBy) {
      params = params.set("sortBy", sortBy);
    }
    if (page) {
      params = params.set("page", page.toString());
    }
    if (limit) {
      params = params.set("limit", limit.toString());
    }
    // console.debug('params', params);
    return this.http
      .get(url, { params })
      .toPromise()
      .then((response) => response);
  }

  getQrCodeLinkQuestByID(id): Promise<any> {
    const url = `${environment.apiUrl}/v1/platform-event/event-shorten-link-quests/${id}`;
    return this.http
      .get(url)
      .toPromise()
      .then((response) => response);
  }

  // QR Code Link Quest //

  async getQuestionnaireByEvent(userId: string, eventCode: string) {
    try {
      const url = `${environment.apiUrl}/v1/platform-event/event-questionnaires/users/${userId}?eventCode=${eventCode}&questionGroup=default`;
      const res: any = await this.http.get(url).toPromise();
      log.debug("fetch get questionnaire from backend result : ", res);
      return res;
    } catch (error) {
      log.error("error on get questionnaire by event ", error);
      return [];
    }
  }

  async getCouponCategory({
    categories = null,
    lang = null,
    isCompleted = null,
    page = 1,
    limit = 9999,
  }) {
    const url = `${environment.apiUrl}/v1/staff-server/allowed/event-coupon-categories/${categories}`;
    let params = new HttpParams();
    if (lang) {
      params = params.set("lang", lang);
    }
    if (isCompleted !== null) {
      params = params.set("isCompleted", isCompleted);
    }
    if (page) {
      params = params.set("page", page.toString());
    }
    if (limit) {
      params = params.set("limit", limit.toString());
    }
    // console.debug('params', params);
    return this.http
      .get(url, { params })
      .toPromise()
      .then((response) => response);
  }

  async getQuest(eventCode) {
    try {
      const url = `${environment.apiUrl}/v1/platform-event/event-quests?eventCode=${eventCode}`;
      const res: any = await this.http.get(url).toPromise();
      log.debug("fetch getQuest result : ", res);
      return res.results;
    } catch (error) {
      log.error("error getQuest", error);
      return [];
    }
  }

  async getUserCouponDetail(
    userId: string,
    eventCode: string,
    lang: string,
    isUsed: boolean,
    limit: any = 20,
    page: any = 1
  ) {
    try {
      const url = `${environment.apiUrl}/v1/platform-event/event-user-coupons?user=${userId}&eventCode=${eventCode}&lang=${lang}&isUsed=${isUsed}&limit=${limit}&page=${page}`;
      const res: any = await this.http.get(url).toPromise();
      log.debug("fetch get user coupon detail from backend result : ", res);
      return res;
    } catch (error) {
      log.error("error on get user info by event ", error);
      return undefined;
    }
  }

  async checkPermission(couponId: string) {
    try {
      const url = `${environment.apiUrl}/v1/staff-server/check-permission/event-coupons/${couponId}`;
      const res: any = await this.http.get(url).toPromise();
      log.debug("fetch get user permission from backend result : ", res);
      return res;
    } catch (error) {
      log.error("error on get user info by event ", error);
      return undefined;
    }
  }

  async checkQuest(executeCode) {
    try {
      const url = `${environment.apiUrl}/v1/staff-server/check-permission/event-quests/execute-code`;
      const res: any = await this.http.post(url, { executeCode }).toPromise();
      log.debug("fetch get user permission quest from backend result : ", res);
      return res;
    } catch (error) {
      log.error("error on get user permission quest by event ", error);
      return { error: true, message: error?.en };
    }
  }

  getUserTempCodeByEvent({
    event = null,
    isUsed = null,
    sortBy = null,
    page = 1,
    limit = 10,
  }: any): Promise<any> {
    const url = `${environment.apiUrl}/v1/platform-event/event-user-temps`;
    let params = new HttpParams();
    if (event) {
      params = params.set("event", event);
    }
    if (typeof isUsed === "boolean") {
      params = params.set("isUsed", isUsed);
    }
    if (sortBy) {
      params = params.set("sortBy", sortBy);
    }
    if (page) {
      params = params.set("page", page.toString());
    }
    if (limit) {
      params = params.set("limit", limit.toString());
    }
    // console.debug('params', params);
    return this.http
      .get(url, { params })
      .toPromise()
      .then((response) => response);
  }

  async generateUserTempCode(payload: any) {
    try {
      const url = `${environment.apiUrl}/v1/staff-server/event-user-temps/generate-code`;
      const res: any = await this.http.post(url, payload).toPromise();
      log.debug("fetch get user permission quest from backend result : ", res);
      return res;
    } catch (error) {
      log.error("error on generateUserTempCode", error);
      return { error: true, message: error?.en };
    }
  }
}
