import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import {
  FacebookLoginProvider,
  SocialAuthService,
} from "@abacritt/angularx-social-login";
import { ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { takeUntil, first } from "rxjs/operators";
import { Subject } from "rxjs";
import { Router } from "@angular/router";
import { CoreConfigService } from "@core/services/config.service";
import { AuthenticationService } from "../../../../auth/service";
import { Logger } from "app/services/logger.service";
import { environment } from "environments/environment";

const log = new Logger("AuthLoginV2Component"); // log.debug
@Component({
  selector: "app-auth-login-v2",
  templateUrl: "./auth-login-v2.component.html",
  styleUrls: ["./auth-login-v2.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AuthLoginV2Component implements OnInit {
  //  Public
  public coreConfig: any;
  public loginForm: FormGroup;
  public loading = false;
  public submitted = false;
  public returnUrl = "/dashboard";
  public error = "";
  public passwordTextType: boolean;

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _coreConfigService: CoreConfigService,
    private _formBuilder: FormBuilder,
    private _route: ActivatedRoute,
    private _router: Router,
    private _authenticationService: AuthenticationService,
    private _socialAuthService: SocialAuthService
  ) {
    this._unsubscribeAll = new Subject();

    // Configure the layout
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        menu: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        customizer: false,
        enableLocalStorage: false,
      },
    };
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  /**
   * Toggle password
   */
  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    // Login
    this.loading = true;
    this.returnUrl = "/dashboard";
    this._authenticationService
      .login(this.f.email.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        (data) => {
          this._router.navigate([this.returnUrl]);
        },
        (error) => {
          this.error = error;
          this.loading = false;
        }
      );
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.loginForm = this._formBuilder.group({
      // email: ['vicharatti.g@gmail.com', [Validators.required]],
      // password: ['C@re1825', Validators.required]
      // email: ['karunyaphat@gmail.com', [Validators.required]],
      // password: ['#123abcZ', Validators.required]
      email: ["", [Validators.required]],
      password: ["", Validators.required],
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this._route.snapshot.queryParams["returnUrl"] || "/";

    // Subscribe to config changes
    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.coreConfig = config;
      });
  }

  /**
   * On destroy
   */
  // tslint:disable-next-line:use-life-cycle-interface
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  ngAfterViewInit() {
    try {
      this.googleSetup();
    } catch (error) {
      log.info(`error: googleSetup()`);
      log.debug(error);
    }
  }

  googleSetup() {
    // @ts-ignore
    google.accounts.id.initialize({
      client_id: environment.googleClientID,
      callback: this.signInWithGoogle.bind(this),
      auto_select: false,
      cancel_on_tap_outside: true,
    });

    log.debug(`init google`);
    // @ts-ignore
    google.accounts.id.renderButton(document.getElementById("google-button"), {
      theme: "outline",
      size: "large",
      shape: "circle",
      type: "icon",
    });

    log.debug(`render button`);
  }

  async signInWithGoogle(user: any) {
    try {
      this.error = "";
      this.loading = true;
      this.returnUrl = "/dashboard";

      const data = await this._authenticationService.loginWithGoogle(
        user.credential
      );
      this._router.navigate([this.returnUrl]);
    } catch (error) {
      log.debug("signInWithGoogle error => %o", error);
      this.error = error;
    } finally {
      this.loading = false;
    }
  }

  async signInWithFacebook() {
    try {
      this.error = "";
      this.loading = true;
      this.returnUrl = "/dashboard";

      const user = await this._socialAuthService.signIn(
        FacebookLoginProvider.PROVIDER_ID
      );

      const data = await this._authenticationService.loginWithFacebook(
        user.authToken
      );
      this._router.navigate([this.returnUrl]);
    } catch (error) {
      log.debug("signInWithFacebook error => %o", error);
      this.error = error;
    } finally {
      this.loading = false;
    }
  }
}
