<div class="table-responsive" *ngIf="!isPagination">
    <table class="table table-bordered">
        <thead>
            <tr>
                <th>Attendee</th>
                <th>Stamp</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let row of history">
                <td>
                    <p class="mb-0">{{row.user.firstName}} {{row.user.lastName}}</p>
                    <p class="text-muted font-small-2 mb-0">{{row.user.email}}, {{row.user.mobilePhone}}</p>
                </td>

                <td>
                    <p class="mb-0">{{row.attendanceDate}}</p>
                    <p class="text-muted font-small-2 mb-0">Stamp by {{row.stampBy.email}}</p>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<ng-container *ngIf="isPagination">
    <ngx-datatable
            class="bootstrap core-bootstrap"
            [columns]="columns"
            [columnMode]="'force'"
            [headerHeight]="50"
            [footerHeight]="50"
            [rowHeight]="'auto'"
            [scrollbarH]="true"
            [externalPaging]="true"
            [count]="totalResults"
            [offset]="page - 1"
            [limit]="amount"
            (page)="onPageChange($event)"
            [rows]="history">
        <ngx-datatable-column name="Name" prop="user.firstName" [width]="280">
            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                <div class="cell-line-height">
                    <p class="font-medium-1 font-weight-bold line-height-1 mb-25">
                        {{ row.user.firstName }} {{row.user.lastName}}
                    </p>
                    <span class="text-muted font-small-2"> {{ row.user.email }}, {{row.user.mobilePhone}}</span>
                </div>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Stamp" prop="attendanceDate" [width]="280">
            <ng-template let-row="row" let-stamp="value" ngx-datatable-cell-template>
                <div class="cell-line-height">
                    <p class="font-medium-1 font-weight-bold line-height-1 mb-25">
                        {{ stamp }}
                    </p>
                    <span class="text-muted font-small-2"> Stamp by {{row.stampBy.email}}</span>
                </div>
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>
</ng-container>
